import React from 'react'
import Project from './project'

const ProjectsOverview = props => {
  return (
    <>
      <h2>Selected Works</h2>
      <section>
      <Project
          title="ib symphony"
          year="2020 - today"
          text="ib symphony empowers the real estate industry to manage their digital systems of their smart buildings on one platform and to integrate new sensors and IoT devices from their economic units. ib symphony makes buildings digital. I'm helping the team in UX/UI design, research and concept, as well as building a design system."
          align="right"
          hasLink="true"
          hasStudy="true"
          link="https://ib-symphony.de"
          linkText="Visit website"
          image="ibsymphony-home"
          backgroundImage="shape-ibsymphony"
          project="ibsymphony"
        />
        <div className="divider" />
        <Project
          title="Certifaction"
          year="2020 - today"
          text="Certification is a digital signage solution based on blockchain technology. Businesses can use certifaction to digitally sign and verify documents to avoid fraud. I’m supporting the team as UX / UI Designer to build the platform."
          align="left"
          hasLink="true"
          hasStudy="true"
          link="https://certifaction.com"
          linkText="Visit website"
          image="certifaction-home"
          backgroundImage="shape-certifaction"
          project="certifaction"
        />
        <div className="divider" />

        <Project
          title="Startbase"
          year="2019 - 2020"
          text="Startbase is the German startup and investor platform by Börse Stuttgart and German Startup Association. The platform helps startups, investors, corporates and accelerators to get visibility, to connect with each other and to discover the German startup ecosystem."
          align="right"
          hasLink="false"
          hasStudy="false"
          link=""
          linkText="Read case study"
          image="startbase-home"
          backgroundImage="shape-startbase"
          project="startbase"
        />
        <div className="divider" />
        <Project
          title="Triphunt"
          year="Since 2018"
          text="Triphunt is an app to plan your next biking or
              hiking trip with your friends or family. Triphunt 
              personalizes the planning with route and gear
              suggestions based on location, sports, weather,
              friends, personal skills, existing gear and off-peak
              times. Triphunt analyses all guest data from 
              different touch points and uses deep learning
              and machine learning to provide the best routes
              and gear for the user."
          align="left"
          hasLink="true"
          hasStudy="true"
          link="/project/triphunt/"
          linkText="Read case study"
          image="phones-home"
          backgroundImage="shape-triphunt"
          project="triphunt"
        />
        <div className="divider" />
        <Project
          title="MountLytics"
          year="2016 - 2019"
          text="MountLytics is a smart CRM system for hotels, which collects guest data from all data silos and combines them in a guest profile. Hotels can use the data to send personalized automation mails with services, information and upsellings. I’m working on this platform since 2016 and built several product states, wireframes and prototypes during the time."
          align="right"
          hasStudy="true"
          hasLink="true"
          link="/project/mountlytics/"
          linkText="Read case study"
          backgroundImage="shape-mountlytics"
          image="mountlytics-home"
          project="mountlytics"
        />
        <div className="divider" />
        <Project
          title="Kapilendo"
          year="2017"
          text="Kapilendo is an investment plattform for P2P credits. I was mainly working on a new user interface design for the user area, but also working on concepts for different investment card designs for the investment classes for their website."
          align="left"
          hasLink="true"
          hasStudy="true"
          link="/project/kapilendo/"
          linkText="Read case study"
          image="kapilendo-home"
          backgroundImage="shape-kapilendo"
          project="kapilendo"
        />
        <div className="divider" />
        <Project
          title="Gore-Tex Club"
          year="2016"
          text="Gore Club was a project with Gore to provide
customers an app to manage their gear, get
cleaning instructions and receive incentives
in the Gore membership."
          align="right"
          hasStudy="true"
          hasLink="true"
          link="/project/goretex/"
          linkText="Read case study"
          backgroundImage="shape-goretex"
          image="goretex-home"
          project="goretex"
        />
        <div className="divider" />
        <Project
          title="Energis"
          year="2015-2016"
          text="Energis built a loyalty program called „Watt für dich“, for their customers. They wanted to transfer the energy generated by movement into a digital product, so that their customers can earn points for sport activities. Points can be used to buy products in the Energis shop. To engage 
more users, they were able to attend challanges with friends or family to earn extra points and badges."
          align="left"
          hasLink="true"
          hasStudy="true"
          link="/project/energis/"
          linkText="Read case study"
          image="energis-home"
          backgroundImage="shape-energis"
          project="energis"
        />
      </section>
    </>
  )
}

export default ProjectsOverview
