import React from 'react'
import Bcodm from '../images/brands/codm.svg'
import Bducati from '../images/brands/ducati.png'
import Benergis from '../images/brands/energis.png'
import Bgoretex from '../images/brands/goretex.png'
import Bkapilendo from '../images/brands/kapilendo.png'
import Bibcompany from '../images/brands/ibcompany.svg'
import Bmountlytics from '../images/brands/mountlytics.png'
import Bnetzdirektion from '../images/brands/netzdirektion.svg'
import Bcertifaction from '../images/brands/certifaction.svg'
import Bswiss from '../images/brands/swissarbitrationhub.svg'
import Bt3n from '../images/brands/t3n.png'
import Bboerse from '../images/brands/boersestuttgart.png'
import Bbund from '../images/brands/bundesverbandstartups.png'
import SVGImage from './svgImage'

const Brands = () => (
  <div className="logos">
    <img
      src={Bboerse}
      alt="Boerse Stuttgart"
      data-aos="fade-up"
      data-aos-delay="50"
    />
    <img
      src={Bbund}
      alt="Bundesverband Deutsche Startups"
      data-aos="fade-up"
      data-aos-delay="50"
    />

    <img src={Bgoretex} alt="Gore-tex" data-aos="fade-up" data-aos-delay="50" />
    <img
      src={Bkapilendo}
      alt="Kapilendo"
      data-aos="fade-up"
      data-aos-delay="100"
    />
    <img
      src={Bmountlytics}
      alt="MountLytics"
      data-aos="fade-up"
      data-aos-delay="150"
    />
    <img
      src={Bcertifaction}
      alt="Certifaction"
      data-aos="fade-up"
      style={{ width: `158px` }}
      data-aos-delay="200"
    />
    <img
      src={Bducati}
      alt="Ducati"
      style={{ width: `54px` }}
      data-aos="fade-up"
      data-aos-delay="250"
    />
    <img src={Benergis} alt="Energis" data-aos="fade-up" data-aos-delay="300" />
    <div data-aos="fade-up" data-aos-delay="350">
      <SVGImage name="ridescout" fill="#9b9dac" width="160" />
    </div>

    <img
      src={Bswiss}
      alt="Swiss Arbitrationhub"
      data-aos="fade-up"
      data-aos-delay="400"
    />
    <img src={Bt3n} alt="t3n" data-aos="fade-up" data-aos-delay="450" />
    <img
      src={Bibcompany}
      alt="ib company"
      style={{ width: `158px` }}
      data-aos="fade-up"
      data-aos-delay="500"
    />
    <div data-aos="fade-up" data-aos-delay="550">
      <SVGImage name="swiftlaunch" fill="#9b9dac" width="160" />
    </div>
    <img
      src={Bnetzdirektion}
      alt="Netzdirektion"
      data-aos="fade-up"
      style={{ width: `158px` }}
      data-aos-delay="600"
    />
    <img src={Bcodm} alt="cod.m" data-aos="fade-up" data-aos-delay="650" />
  </div>
)

export default Brands
