import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import Brands from '../components/brands'
import QuoteComponent from '../components/QuoteComponent'
import ProjectsOverview from '../components/projectsOverview'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Welcome"
      keywords={[
        `Venture Architect`,
        `MVP Development`,
        `User Experience Designer`,
        `UX`,
        `UI`,
        `Interactive Design`,
        `Interactive Media Design`,
        `Design Thinking Darmstadt`,
        `Danny Giebe`,
        `Front-end Developer`,
        `Interdisciplinary Designer`,
        `Design Consulting`,
        `Design Thinking Workshop Darmstadt`,
        `IoT Designer`,
      ]}
    />
    <header className="hero">
      <div className="hero-content">
        <div className="hero-text">
          <h1>
            Hi there, I’m Danny! <br />
            I’m a Digital Product Designer
          </h1>
          <p>
            I’m passionate about solving problems to make humans satisfied when
            they’re using digital products and services. I'm building &
            designing digital products since 2008, with the focus on
            innovation & strategy, UX/UI design & front-end development.
          </p>
          <a className="btn" href="https://calendly.com/dannygiebe/connect">Schedule a call</a>
          
        </div>
      </div>
    </header>
    <div className="divider" />

    <ProjectsOverview />

    <section>
      <h2>What clients say</h2>
      <div className="section-columns">
        <QuoteComponent
          brand="boerse"
          author="- Stefan Nothacker, Börse Stuttgart"
          quotetext="We became aware of Danny by recommendation. We are happy to pass on the recommendation! Danny supports us as a designer in the early stages of developing a digital platform. He especially was responsible for the product-, UX and UI design. Danny has a very good and comprehensive understanding of digital products, processes and requirements. He is creative, initiative, positively critical, works reliably and thinks holistically. With Danny's help we have managed to develop a great product in a short time. He enriches every team with his open and friendly way."
        />
        <QuoteComponent
          brand="mountlytics"
          author="- Daniel Litschel, Product Manager"
          quotetext="Product Managers and UX Designers often have to discuss important business decisions. It is way more than looking at buttons, positions and colors. It's really about understanding the business, creating the product and still have an eye on details is really difficult to manage. Danny helps you to manage this and make it seem easy and fun. A real inspiration! He has all the skills to create awesome products and make them special."
        />
        <QuoteComponent
          brand="codm"
          author="- Patrik Mayer, CEO codm"
          quotetext="Danny has completely convinced us in all design matters. Through his in-depth understanding of user-interface design, but above all, the expertise for holistic development of the user experience, all of our clients' projects have gained quality through Danny's cooperation."
        />
        <QuoteComponent
          brand="ridescout"
          author="- Jordan Ramirez, Ridescout"
          quotetext="Danny is a kind and considerate designer - an expert in his craft.
              Danny will coordinate and communicate with you more effectively
              than most designers. I have worked with many different creatives
              throughout my career, and I know that Danny is one of the most
              reliable and efficient creative minds that I‘ve ever been able to
              come across. Work with him, you won‘t be disappointed - I know that I never
              was."
        />

        <QuoteComponent
          brand="swiftlaunch"
          author="- James Pinto, CEO Swiftlaunch"
          quotetext="Danny is an extremely outstanding and talented young individual.
              He is exceptionally talented, with a strong work ethic, a great
              team attitude, and tremendous vision. His skills reflect
              impeccable design, frontend development, creative ideation, and UX
              work. Moreover, he has ever increasing leadership and management
              skills. I can wholly recommend Danny for any web front-end
              development and design opportunity that may arise."
        />
      </div>

      <h4 className="text-center" data-aos="fade-up" data-aos-delay="100">
        SOME CLIENTS I’M HAPPY TO HAVE WORKED WITH
      </h4>

      <Brands />
    </section>
  </Layout>
)

export default IndexPage
